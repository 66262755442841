<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="salvar" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="6" md="4" v-if="!id_responsavel"><SelectFranquia hide-details label="* Franquia:" v-model="registro.id_franquia" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Razão social / Nome completo:" v-model="registro.razao_social_nome_completo" /></v-col>
                            <v-col cols="12" sm="6" md="4" v-if="!id_responsavel"><v-text-field hide-details label="* CPF/CNPJ:" v-model="registro.cnpj_cpf" v-mask="['###.###.###-##', '##.###.###/####-##']" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-select hide-details label="Sexo:" :items="[{value : 't', text : 'Masculino'}, {value : 'f', text : 'Feminino'}]" v-model="registro.sexo" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="Data de nascimento:" v-model="registro.data_nascimento" v-mask="'##/##/####'" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-select hide-details label="Estado civil:" :items="selectEstadoCivil" v-model="registro.id_estado_civil" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* CEP:" v-model="registro.cep" v-mask="'#####-###'" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-autocomplete hide-details label="* Estado:" :items="selectEstado" v-model="registro.id_estado" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-autocomplete hide-details label="* Cidade:" :items="selectCidade" v-model="registro.id_cidade" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Bairro:" v-model="registro.bairro" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Logradouro:" v-model="registro.logradouro" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="Número:" v-model="registro.numero" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="E-mail:" v-model="registro.email" type="email" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="Complemento:" v-model="registro.complemento" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Celular:" v-model="registro.celular" v-mask="['(##)  ####-####', '(##) #.####-####']" /></v-col>
                            <v-col cols="12"><v-textarea hide-details label="Observações:" v-model="registro.observacao" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import {mask} from 'vue-the-mask'
import {mapState} from 'vuex'
import SelectFranquia from "@/Views/Estrutura/Form/SelectFranquia.vue";

export default {
    name: "ResponsavelForm",
    components: {SelectFranquia},
    props : ['id_responsavel'],
    directives : {mask},
    data() {
        return {
            registro : {},
            erro : null,

            selectGrupo : [],
            selectEstado : [],
            selectCidade : [],
            selectEstadoCivil : []
        }
    },
    computed : {
        ...mapState(['baseUrl', 'usuarioSessao'])
    },
    methods : {
        getResponsavel() {
            axios.post(`${this.baseUrl}responsavel/get`, {id_responsavel : this.id_responsavel}).then((res) => {
                if(res.data.erro) {
                    this.$router.push({path : '/responsavel'})
                }
                this.registro = {
                    ...res.data,
                    data_nascimento : this.dataPt(res.data.data_nascimento)
                }
            })
        },
        selectCidadeListar() {
            if(this.registro.id_estado) {
                return axios.post(`${this.baseUrl}endereco/cidade_listar`, {id_estado : parseInt(this.registro.id_estado)}).then((res) => {
                    this.selectCidade = res.data.lista.map((c) => {return {
                        value : c.id_cidade,
                        text : c.cidade
                    }})
                })
            } else {
                this.selectCidade = []
            }
        },
        selectEstadoListar() {
            return axios.post(`${this.baseUrl}endereco/estado_listar`).then((res) => {
                this.selectEstado = res.data.lista.map((rt) => {
                    return {value : rt.id_estado, text : rt.estado}
                })
            })
        },
        selectEstadoCivilListar() {
            return axios.post(`${this.baseUrl}responsavel/estado_civil_listar`).then((res) => {
                this.selectEstadoCivil = res.data.lista.map((rt) => {
                    return {value : rt.id_estado_civil, text : rt.estado_civil}
                })
            })
        },
        salvar() {
            if(!this.id_responsavel) {
                axios.post(`${this.baseUrl}responsavel/cadastrar`, {...this.registro, cep : this.mascaraRemover(this.registro.cep), cnpj_cpf : this.mascaraRemover(this.registro.cnpj_cpf), celular : this.mascaraRemover(this.registro.celular), data_nascimento : this.dataEn(this.registro.data_nascimento)}).then( (res) => {
                    if(res.data.erro == true) {
                        this.erro = res.data.mensagem
                    }
                    else {
                        this.erro = null
                        this.$router.push({path : '/responsavel/gerenciar/' + res.data.id_responsavel})
                    }
                })
            }
            else {
                axios.post(`${this.baseUrl}responsavel/alterar`, {...this.registro, cep : this.mascaraRemover(this.registro.cep), celular : this.mascaraRemover(this.registro.celular), data_nascimento : this.dataEn(this.registro.data_nascimento)}).then( (res) => {
                    if(res.data.erro == true) {
                        this.erro = res.data.mensagem
                    }
                    else {
                        this.erro = null
                        this.$router.push({path : '/responsavel/gerenciar/' + this.id_responsavel})
                    }
                })
            }
        },
        async init() {
            this.registro = {}
            await this.selectEstadoCivilListar()
            await this.selectEstadoListar()
            if(this.id_responsavel) {
                await this.getResponsavel()
            }
        }
    },
    watch : {
        'registro.id_estado' : function () {
            this.selectCidadeListar().then((res) => {
                if(res.filter(v => v.value == this.registro.id_cidade).length == 0) {
                    this.registro.id_cidade = null
                }
            })
        }
    },
    activated : function() {
        this.init()
    }
}
</script>

<style scoped>

</style>
